<template>
    <div class="hiq-events-list__search-results--hits__hit" :class="swiper ? ' swiper' : ''">            
        <a :href="hit.url" class="hiq-events-list__search-results--hits__hit--link" :title="hit.title">
            <div class="hiq-events-list__search-results--hits__hit--image__wrapper">
                <img class="lazyload hiq-events-list__search-results--hits__hit--image opacity-lazy" data-expand="-10" :data-src="hit.image.mediumSrc || hit.image.src" :alt="hit.image.alt" />
            </div>
            <div class="hiq-events-list__search-results--hits__hit--short-date" v-html="getShortDate(hit.info.start, hit.info.end)"></div>
            <div class="hiq-events-list__search-results--hits__hit--text-content">
                <div class="hiq-events-list__search-results--hits__hit--header">
                    {{hit.title}}
                </div>
                <div class="hiq-events-list__search-results--hits__hit--info">
                    <span 
                        v-if="hit.info.start"
                        class="hiq-events-list__search-results--hits__hit--info__date" 
                    >
                        <span 
                            aria-hidden="true" 
                            class="hiq-events-list__search-results--hits__hit--info__date--icon"
                        >
                        </span>
                        {{getTime(hit.info.start)}}
                    </span>
                    <span 
                        v-if="hit.info.location && hit.info.location.name"
                        class="hiq-events-list__search-results--hits__hit--info__location" 
                    >
                        <span 
                            aria-hidden="true" 
                            class="hiq-events-list__search-results--hits__hit--info__location--icon"
                        >
                        </span>
                        {{hit.info.location.name}}
                    </span>
                </div>             
            </div>        
        </a>
    </div>

</template>

<script>
import Vue from 'vue';
import moment from 'moment';

var locale = document.documentElement.lang;
moment.locale(locale);

export default {
    name: 'Event',
    props: {
        hit: Object,
        swiper: Boolean,
    },
    data() {
        return {
            
        }
    },
    methods: {
        getTime(dateString){
            return moment(dateString).format('HH.mm');
        },
        getShortDate(startDate, endDate){
            var formattedDate = '';
            startDate = moment(startDate);
            endDate = moment(endDate);
            formattedDate = startDate.format('DD MMM');
            if(!startDate.isSame(endDate, 'day')){
                formattedDate += ' - ' + endDate.format('DD MMM');
            }
            return formattedDate;
        },
    },
    computed: {}
}
</script>