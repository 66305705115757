import { Getter } from "vuex";
import { IState } from './state';

const heading: Getter<IState, IState> = (state) => state.heading;
const events: Getter<IState, IState> = (state) => state.events;
const showAmount: Getter<IState, IState> = (state) => state.showAmount;
const showLoadMore: Getter<IState, IState> = (state) => state.showLoadMore;
const useSwipe: Getter<IState, IState> = (state) => state.useSwipe;
const count: Getter<IState, IState> = (state) => state.searchInfo.count;
const totalHits: Getter<IState, IState> = (state) => state.searchInfo.totalHits;
const page: Getter<IState, IState> = (state) => state.searchInfo.page;
const type: Getter<IState, IState> = (state) => state.type;
const showAllPage: Getter<IState, IState> = (state) => state.showAllPage;
const showAllPageLink: Getter<IState, IState> = (state) => {
	var base = state.showAllPage;
	if(!base){
		return false;
	}
	if(state.type === 'filter'){
		var filters = JSON.parse(state.filters);
		var searchFilters = {};
		for(var key in filters){
			if(key !== 'start' && key !== 'end' && key !== 'location' && key !== 'type'){
				var group = filters[key];
				group.forEach((option: string) => {
					if (option !== '') {
						if (!searchFilters[key]) {
							searchFilters[key] = option;
						} else {
							searchFilters[key] += "," +option;
						}
					}
				});
			} else {
				if(filters[key] && filters[key] !== ''){
					searchFilters[key] = filters[key];
				}
			}
		}
		var query = state.searchInfo.query ? state.searchInfo.query : '';
		base += '?query=' + query;
		for(var key in searchFilters){
			if(searchFilters.hasOwnProperty(key)){
				base += '&' + encodeURIComponent(key) + '=' + encodeURIComponent(searchFilters[key]);
			}
		}
	} else if(state.type === 'category') {
		base += '?category=' + encodeURIComponent(state.categories.join(','));
	}
	return base;
}
export default {
	heading,
	events,
	showAmount,
	showLoadMore,
	useSwipe,
	count,
	totalHits,
	page,
	type,
	showAllPage,
	showAllPageLink,
}
