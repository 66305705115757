<template>
    <div>
        <button 
            class="hiq-button hiq-button__primary hiq-events-list__loadMore"
            @click="loadMore"
        >
            Visa fler
        </button>
    </div>
</template>

<script>
export default {
    name:'LoadMore',
    computed: {
        type() { return this.$store.getters.type },
        page() { return this.$store.getters.page },
    },
    methods: {
        loadMore(){
            if(this.type === 'filter'){
                this.$store.commit({
                    type: 'setSearchInfo',
                    payload: {
                        page: this.page + 1,
                        timestamp: Date.now(),
                    }
                });
                this.$store.dispatch('newSearch');
            } else {
                this.$store.commit({
                    type: 'loadMore',
                    payload: {}
                });
            }
            
        },
    }
}
</script>